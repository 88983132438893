<script setup>
import {route} from "ziggy-js";
import {useForm, usePage} from "@inertiajs/vue3";

function googleOneTap(
    {
        client_id,
        auto_select = false,
        cancel_on_tap_outside = false,
        context = "signin",
        ...otherOptions
    },
    callback
) {
    if (!client_id) {
        throw new Error("client_id is required");
    }

    if (typeof window !== "undefined" && window.document) {
        const contextValue = ["signin", "signup", "use"].includes(context)
            ? context
            : "signin";
        const googleScript = document.createElement("script");
        googleScript.src = "https://accounts.google.com/gsi/client";
        googleScript.async = true;
        googleScript.defer = true;
        document.head.appendChild(googleScript);
        googleScript.onload = function () {
            // console.log("Google One Tap script loaded");
            if (!window.google || !window.google.accounts) {
                console.warn(
                    "Google One Tap is not available. Please check your internet connection or try again later."
                );
                return;
            }
            window.google.accounts.id.initialize({
                client_id: client_id,
                callback: callback,
                auto_select: auto_select,
                cancel_on_tap_outside: cancel_on_tap_outside,
                context: contextValue,
                ...otherOptions,
            });
            window.google.accounts.id.prompt();
        };
    }
}

const form = useForm({
    credential: ''
});
const options = {
    client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID ?? '',
    auto_select: true,
    cancel_on_tap_outside: false
};
const page = usePage()
const isAuthenticated = page.props.auth?.user ?? false
if (!isAuthenticated && options.client_id) {
    googleOneTap(options, (response) => {
        // Send response to server
        form.credential = response.credential;
        form.post(route('oauth.callback', {provider: 'google'}));
    });
}
if (!options.client_id) {
    console.warn('Google One Tap is disabled. Please provide VITE_GOOGLE_CLIENT_ID in .env file');
}
</script>

<template>

</template>
